<template>
  <b-modal
    id="location-modal"
    centered
    hide-footer
    title-sr-only
    size="lg"
    content-class="bg-dark text-light text-center"
    title="Location"
    header-close-variant="light"
  >
    <template #modal-header="{ close }">
      <b-container fluid>
        <b-row class="text-center">
          <!-- Required for equal spacing -->
          <b-col></b-col>

          <b-col cols="8">
            <img
              src="../../assets/trackd-icon-colours.svg"
              class="mb-4 mx-auto text-center mt-2"
              height="35"
              alt="Logo"
            />
          </b-col>

          <b-col class="float-right">
            <b-button
              size="sm"
              variant="link"
              class="float-right"
              @click="close()"
            >
              <img
                src="../../assets/section-close.svg"
                class="mx-auto text-right float-right mt-2"
                height="23"
                alt="Close"
              />
            </b-button>
          </b-col>
        </b-row>
      </b-container>
    </template>

    <h4 class="font-weight-lighter py-3 h2">We need your location</h4>

    <p class="font-weight-light">
      Please enter the country you wish the account to be setup in. This needs
      to be the same as your bank account.
    </p>

    <b-row class="mb-5 pb-5">
      <div class="col-8 offset-2 col-md-6 offset-md-3">
        <p v-if="error" class="text-danfer">Please select a country</p>

        <div>
          <SettingsSelectInput
            v-model="selected"
            :options="countries"
            required
            :type="'country'"
            @change="onChange"
            class="input-width select-input-margin"
          ></SettingsSelectInput>
        </div>
      </div>
    </b-row>

    <b-button
      variant="info"
      class="px-5 mx-3 py-2 my-3 rounded-btn text-uppercase"
      @click="Submit"
    >
      Confirm
    </b-button>
  </b-modal>
</template>

<script>
import { mapActions } from "vuex";
import SettingsSelectInput from "../../components/settings/select-input.vue";

export default {
  name: "LocationModal",
  components: {
    SettingsSelectInput,
  },
  data() {
    return {
      selected: "",
      countries: [],
      error: false,
    };
  },
  methods: {
    ...mapActions("Account", {
      get_location: "getLocationChoices",
    }),
    ...mapActions("Chipin", ["chipin_register"]),
    onChange(selection) {
      this.selected = selection;
    },
    Submit() {
      if (!this.selected) {
        this.error = true;
      }

      this.error = false;
      this.chipin_register({ country: this.selected })
        .then((res) => {
          this.$bvModal.hide("location-modal");
          this.register_clicked = false;
          window.location = res.data.url;
        })
        .catch((error) => {
          error;
          this.$bvModal.hide("location-modal");
          this.$parent.error_message = error.data.errors[0];
          this.$parent.register_clicked = false;
          this.$parent.error_message = null;
        });
    },
    mapList(list) {
      let formatted_list = [{ value: null, text: "Please select" }];
      list.forEach(function(item) {
        formatted_list.push({ value: item.id, text: item.name });
      });

      return formatted_list;
    },
  },
  computed: {},
  mounted() {
    this.get_location({}).then((res) => {
      this.countries = this.mapList(res);
    });
  },
};
</script>

<style scoped>
.small {
  font-size: 14px;
}

.underline {
  text-decoration: underline;
}

.underline:hover {
  text-decoration: none;
}

.next-button {
  background-color: rgb(74, 173, 215);
  border-radius: 10px;
  width: 4.625em;
  height: 2.5em;
}

.done-btn {
  border: solid white 1px;
  background-color: transparent;
  width: 90px;
  height: 35px;
}
</style>
