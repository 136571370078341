<template>
  <b-modal
    id="email-modal"
    centered
    hide-footer
    title-sr-only
    size="lg"
    content-class="bg-dark text-light text-center"
    title="Email"
    header-close-variant="light"
  >
    <template #modal-header="{ close }">
      <b-container fluid>
        <b-row class="text-center">
          <!-- Required for equal spacing -->
          <b-col></b-col>

          <b-col cols="8">
            <img
              src="../../assets/trackd-icon-colours.svg"
              class="mb-4 mx-auto text-center mt-2"
              height="35"
              alt="Logo"
            />
          </b-col>

          <b-col class="float-right">
            <b-button
              size="sm"
              variant="link"
              class="float-right"
              @click="close()"
            >
              <img
                src="../../assets/section-close.svg"
                class="mx-auto text-right float-right mt-2"
                height="23"
                alt="Close"
              />
            </b-button>
          </b-col>
        </b-row>
      </b-container>
    </template>

    <h4 class="font-weight-lighter py-3 h2">We need your email addess</h4>

    <b-row class="mb-5 pb-5">
      <div class="col-12 col-md-10 offset-md-1 ">
        <div>
          <b-form-group
            class="my-0 py-0"
            id="input-group-2"
            label-class="text-light font-weight-light py-0 m-0 "
            label-for="input-2"
            :state="email_error"
          >
            <SettingsTextInput
              id="input-2"
              v-model="email"
              v-bind:placeholder_text="`Enter email`"
              v-bind:type="'email'"
              :state="email_error"
              class="input-width"
            ></SettingsTextInput>

            <b-form-invalid-feedback v-if="email_error_msg"
              >{{ email_error_msg }}
            </b-form-invalid-feedback>
          </b-form-group>
        </div>
      </div>
    </b-row>

    <b-button
      variant="info"
      class="px-5 mx-3 py-2 my-3 rounded-btn text-uppercase"
      @click="Submit"
    >
      Confirm
    </b-button>
  </b-modal>
</template>

<script>
import { mapActions } from "vuex";
import SettingsTextInput from "../../components/settings/text-input.vue";

export default {
  name: "EmailModal",
  components: {
    SettingsTextInput,
  },
  data() {
    return {
      email_error: false,
      email_error_msg: null,
      email: null,
    };
  },
  methods: {
    ...mapActions("Onboarding", {
      validate_email: "validate_email",
      update: "set_username",
    }),
    ...mapActions("Chipin", ["chipin_register"]),
    onChange(selection) {
      this.selected = selection;
    },
    Submit() {
      if (!this.email) {
        this.email_error = true;
        this.email_error_msg = "This field is required.";
        return;
      }

      this.email_error = false;
      this.email_error_msg = null;

      var data = {};
      data["device_id"] = "hfhfjddkdns";
      data["email"] = this.email;
      
      this.validate_email(data)
        .then((res) => {
          res;

          this.update(data)
            .then((res) => {
              res;
              this.$bvModal.hide("email-modal");
              this.$parent.register_clicked = false;
              this.$parent.error_message = null;
            })
            .catch((error) => {
              error;
            });
        })
        .catch((error) => {
          error;
          console.log(error.data.errors[0])
          this.email_error = true;
          this.email_error_msg = error.data.errors[0];
        });
    },
  },
  computed: {},
};
</script>

<style scoped>
.small {
  font-size: 14px;
}

.underline {
  text-decoration: underline;
}

.underline:hover {
  text-decoration: none;
}

.next-button {
  background-color: rgb(74, 173, 215);
  border-radius: 10px;
  width: 4.625em;
  height: 2.5em;
}

.done-btn {
  border: solid white 1px;
  background-color: transparent;
  width: 90px;
  height: 35px;
}

.rounded-btn {
  border-radius: 10px;
}
</style>
